.mediaCoverageContainer {
  background-color: #fffcf7;
}

.mediaCoverageImageBanner {
  height: 300px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .mediaCoverageImageBanner {
    height: 200px;
  }
}

.mediaCoverageImageStyling {
  width: 400px;
  height: 400px;
  margin: 10px;
  border-radius: 10px;
}

.mediaCoverageImageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width: 996px) {
  .mediaCoverageImageContainer {
    width: 100%;
  }
  .mediaCoverageImageStyling {
    width: 170px;
    height: 170px;
    margin: 10px;
  }
}

@media screen and (max-width: 400px) {
  .mediaCoverageImageStyling {
    height: 140px;
    width: 140px;
  }
}
