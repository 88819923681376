.volunteerBg {
  height: 300px;
  width: 100%;
  background-size: contain;
}

.mobileBannerVolunteer {
  display: none;
}

.arrangeVolunteerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.volunteerPara {
  color: #7a7a7a;
  text-align: justify;
  font-family: Calibri, "Sathoshi", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  line-height: 1.2;
  width: 90%;
  margin-top: 30px;
}

.quoteContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border: 0px solid black;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  background-color: #f0ece9;
  font-family: Calibri, "Sathoshi", sans-serif;
}

.arrangeBgVolunteer {
  padding-bottom: 100px;
}

.nameQuote {
  font-size: 28px;
  align-self: flex-end;
  margin-top: 50px;
  margin-right: 25px;
  font-family: Calibri, "Sathoshi", sans-serif;
}
.quoteVolunteer {
  font-size: 28px;
  width: 100%;
  color: black;
  text-align: left;
  line-height: 1.4;
  font-family: Calibri, "Sathoshi", sans-serif;
}

.instructionsVolunteer {
  text-align: left;
  font-size: 32px;
  font-weight: bold;
  font-family: Calibri, "Sathoshi", sans-serif;
}

.instructionsContainer {
  padding: 25px;
  margin: auto;
  width: 90%;
}

.arrangeInstructionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 90%;
  margin-bottom: 25px;
}

.beAVolunteerHeading {
  margin-bottom: 0px;
}

.knowMoreVolunteer {
  border: none;
  border-radius: 6px;
  background: #f2902f;
  color: white;
  cursor: pointer;
  padding: 15px;
  font-size: 24px;
  justify-self: center;
  font-family: Calibri, "Sathoshi", sans-serif;
}

@media screen and (max-width: 996px) {
  .knowMoreVolunteer {
    font-size: 12px;
    padding: 10px;
  }
}

.numberContainer {
  background-color: #e0dddd;
  color: #617be3;
  font-size: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90px;
  padding: 5px;
}

@media screen and (max-width: 996px) {
  .numberContainer {
    font-size: 18px;
    height: 60px;
  }
}

.numberParaElement {
  color: #000;
  text-align: left;
  padding-left: 32px;
  font-family: Calibri, "Sathoshi", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-family: Calibri, "Sathoshi", sans-serif;
  font-weight: 400;
  line-height: 1.4; /* 133.333% */
}

.knowMoreVolunteerContainer {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.numberParaEleContainer {
  width: 100%;
}

.joinWithUsBorder{
  border: 1.8px solid #E0DDDD;
  width: 95%;
  margin-top: 22px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 996px) {
  .volunteerPara {
    font-size: 14px;
  }
  .quoteVolunteer {
    font-size: 14px;
  }
  .instructionsVolunteer {
    font-size: 18px;
    margin-bottom: 0px;
  }
  .numberParaElement {
    font-size: 16px;
  }
  .arrangeInstructionContainer {
    margin-bottom: 12px;
  }
  .nameQuote {
    font-size: 12px;
    margin-top: 10px;
  }
  .volunteerBg {
    height: 45vh;
    display: none;
  }
  .mobileBannerVolunteer {
    display: block;
    height: 200px;
  }
}

.knowMoreVolunteerbtn1 {
  border-radius: 6px;
  border: 1px solid #000;
  background: #fff;
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #000;
  text-align: center;
  font-family: "Satoshi", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-transform: uppercase;
  cursor: pointer;
}

@media screen and (max-width: 996px) {
  .knowMoreVolunteerbtn1 {
    font-size: 12px;
    padding: 5px;
  }
}
