.annamparabrahmam-image {
  height: 300px;
  width: 100%;
  background-size: contain;
}

@media screen and (max-width: 768px) {
  .annamparabrahmam-image {
    height: 237px;
  }
}

.annamparabrahmam-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto;
  gap: 30px;
}

.annamparabrahmam-heading {
  color: #222;
  font-family: "Sathoshi", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 108.3%; */
}

@media screen and (max-width: 768px) {
  .annamparabrahmam-heading {
    font-size: 28px;
  }
}

.annamparabrahmam-description {
  color: black;
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 117.3%; */
  font-family: "Sathoshi", sans-serif;
  margin-top: 0px;
  line-height: 33px;
}

@media screen and (max-width: 768px) {
  .annamparabrahmam-description {
    font-size: 16px;
    line-height: 25px;
  }
}

.annamparabrahmam-leader-container {
  display: flex;
  padding: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  width: 90%;
  margin: auto;
  background: #ecd8b9;
  margin-top: 70px;
  margin-bottom: 50px;
  border-radius: 8px;
}

.leader-card-container {
  display: flex;
  height: 307px;
  padding: 25px 26px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  background: #ecf1f0;
  border-radius: 12px;
}

@media screen and (max-width: 768px) {
  .leader-card-container {
    height: 250px;
    padding: 10px 15px;
  }
}

.leader-image-and-text {
  display: flex;
  height: 257px;
  align-items: center;
  gap: 25px;
  flex-shrink: 0;
}

.leader-text-container {
  display: flex;
  width: 343.05px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .leader-text-container {
    width: 250px;
  }
}

.leader-name {
  color: #2e4049;
  font-family: "Shippori Mincho";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 768px) {
  .leader-name {
    font-size: 20px;
  }
}

.project {
  color: #727272;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26px */
}

@media screen and (max-width: 768px) {
  .project {
    font-size: 16px;
  }
}

.leader-description {
  color: #727272;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26px */
}

@media screen and (max-width: 768px) {
  .leader-description {
    font-size: 16px;
  }
}

.leader-image {
  border-radius: 15px;
}

@media screen and (max-width: 768px) {
  .leader-image {
    width: 90px;
    height: 140px;
  }
}
