.bloodImage {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transform: scaleX(-1);
}

.donateContainer {
  padding-bottom: 15px;
}

.bloodContainerText {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
  padding: 25px;
  font-size: 32px;
}

.main-blood-heading-donate {
  font-size: 48px;
}

.main-sub-heading {
  padding: 10px;
  font-size: 24px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  margin-top: 10px;
  padding-top: 0px;
}

.main-blood-heading {
  padding: 10px;
  font-size: 48px;
  text-align: center;
  margin-bottom: 10px;
}

.bloodDonateArrange {
  display: flex;
  margin: auto;
}

.imageBloodStyling {
  width: 100%;
  height: 350px;
  border-radius: 10px;
  border: 2px solid red;
}

.donateImageContainer {
  flex-grow: 0;
  align-self: center;
  margin-right: 100px;
}

.donateImageContainerDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 10px;
}

.donateImageContainerMain {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 48px;
  gap: 80px;
}

.bloodDonateContents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
}

.donate-para {
  width: 60%;
  text-align: left;
  margin-top: 40px;
  font-size: 22px;
  margin-left: 8vw;
}

.donate-heading-what {
  text-align: left;
  margin-left: 8vw;
}

.how-do-blood-container {
  text-align: left;
  margin-left: 20px;
}

.mobile-donate-para {
  display: none;
}

.scroll-div {
  width: 3px;
  height: 300px; /* Initial height */
  background-color: #af0b0b; /* Initial background color */
  transition: 2s ease; /* Smooth transition for height change */
}

.backgroundRedDonate {
  background-color: #af0b0b;
  transition: height 0.1s ease;
}

.main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.donateDetailsHowWeDoIt {
  gap: 5px;
  width: 39vw;
  height: max-content;
  border: 2px solid #991b1b;
  border-radius: 16px;
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  padding-left: 40px;
}

.subHeadingDonate {
  margin-bottom: 20px;
  font-size: 32px;
}

.donate-para-2 {
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  margin-top: 11px;
  line-height: 33px;
}

.sample-div {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.border-1 {
  border-radius: 100px;
  width: 100px;
  height: 100px;
  background: white;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.redBack {
  background-color: #af0b0b;
  transition: 0.5s ease;
}

.numberClass {
  font-size: 36px;
}

.arrange-in-row {
  display: flex;
  justify-content: flex-start;
}

.finished-scroll {
  background-color: #af0b0b;
  transition: 0.1s ease;
}

.howWeDoIt {
  text-align: left;
  width: 50%;
  margin: auto;
  padding-bottom: 30px;
  font-size: 36px;
}

.BloodcardContainerMain {
  background-color: white;
  border: 3px solid #d70020;
  border-radius: 10px;
  padding: 25px;
  position: relative;
  width: 40%;
  margin-bottom: 30px;
  margin: 10px;
  min-height: 200px;
}

.donateHeadingMain {
  color: #000;
  font-family: "Sathoshi", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-top: 25px;
}

.step1 {
  width: 70px;
  position: absolute;
  top: -20px;
  left: 20px;
  border-radius: 32px;
  padding: 14px 20px;
  background: #d70020;
  color: white;
  font-family: "Sathoshi", sans-serif;
  text-align: center;
}

.donateParaMain {
  color: #000;
  font-family: "Sathoshi", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 25px;
  text-align: left;
}

.mainContainerDonateStyling {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px;
  width: 100%;
  margin: auto;
}

.howWeDoItHeading {
  width: 85%;
  padding-bottom: 25px;
  margin: auto;

  margin-bottom: 48px;
  font-size: 38px;
}

.backgroundBloodStepsContainer {
  padding: 25px;
  margin-top: 20px;
}

.feedBackHeading {
  color: #000;
  margin-bottom: 20px;
  font-family: "Sathoshi", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.feedBackPara {
  color: #707070;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 75%;
  font-family: "Sathoshi", sans-serif;
}
.FeedBackContainer {
  display: flex;
  text-align: left;
  width: 90%;
  margin: auto;
  padding: 10px;
}

.colorDonateContainer {
  border-radius: 20px;
  background: #e8e8e8;

  padding: 25px;
}

.donateProjectContainerFeebback {
  width: 80%;
  background-color: #fffcf7;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  border-radius: 16px;
  padding: 25px;
}

.bloodImageProject {
  width: 760px;

  height: 338px;
  border-radius: 16px;
  border: #d70020 solid 2px;
}
.WhatDoesBloodConnect {
  font-size: 32px;
  text-align: left;
  font-size: 30px;
  margin-bottom: 25px;
}

.donate-para-text {
  font-size: 26px;
  width: 80%;
  margin-top: 10px;
  line-height: 1.4;
  text-align: left;
  order: 3;
  align-self: center;
}

.contentsBloodConnectQuestion{
  width: 60%;
}

.feedBackOption {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.feedBackParaOption {
  color: #2f71f2;
  text-align: center;
  font-family: "Satoshi, sans-serif";
  font-size: 18px;
  font-style: normal;
  font-weight: 400; /* 111.111% */
  text-decoration-line: underline;
  text-transform: capitalize;
  font-family: "Sathoshi", sans-serif;
  cursor: pointer;
  height: fit-content;
  background-color: #ffffff;
  border: none;
}

.redBackgroundButton {
  background-color: #d70020;
  margin: 5px;
  width: 40%;

  padding: 30px;
  font-size: 20px;
  color: white;
  border-radius: 16px;
  border: 3px solid #d70020;
  margin-left: 50px;
}
.requestBloodButton {
  margin: 5px;
  width: 40%;
  font-size: 20px;
  border-radius: 16px;
  border: 3px solid #d70020;
  color: #d70020;
  height: 65px;
  cursor: pointer;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

.redBackgroundButton {
  background-color: #d70020;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.feedBackButtonsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 60px;
  margin-top: 32px;
}

.buttonFeedBackLink {
  width: 100%;
}

.requestButtonLink {
  width: 100%;
}

.containerBloodImage{
  position: relative;
  bottom: 50px;
  left: 25px;
}

.text-overlay-blood-connect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -70%);
  color: white;
  font-size: 24px;
  font-family: 'Sathoshi', sans-serif;
  text-align: center; 
  padding: 10px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.questionImageStyling{
  width: 180px;
}

@media screen and (min-width: 1750px) {
  .bloodDonateArrange {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .donateImageContainer {
    flex-grow: 0;
  }
}

@media screen and (max-width: 768px) {
  .bloodImage {
    height: 200px;
  }
  .backgroundBloodStepsContainer {
    margin-top: 15px;
  }
  .bloodDonateArrange {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .FeedBackContainer {
    display: flex;
    flex-direction: column;
  }

  .donateImageContainer {
    align-self: center;
  }

  .bloodDonateContents {
    text-align: center;
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .main-blood-heading {
    padding: 0px;
    font-size: 28px;
    margin-top: 25px;
  }

  .main-sub-heading {
    font-size: 16px;
  }

  .donate-heading-what {
    text-align: center;
    font-size: 26px;
    order: 1;
  }

  .border-1 {
    width: 70px;
    height: 70px;
  }

  .numberClass {
    font-size: 25px;
  }

  .scroll-div {
    height: 350px;
  }

  .donate-para {
    text-align: center;
    order: 5;
    display: none;
    width: 100%;
  }

  .mobile-donate-para {
    display: block;
    margin-top: 30px;
    text-align: left;
    margin: 0px;
  }
  .mobileParaDonate {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    margin-top: 25px;
  }

  .bloodContainerText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    padding-left: 0px;
  }

  .main-blood-heading {
    text-align: center;
  }

  .main-sub-heading {
    text-align: center;
    margin-top: 5px;
  }

  .imageBloodStyling {
    margin-right: 0px;
    width: 75%;
    height: 50%;
    order: 2;
  }
}

@media screen and (max-width: 1000px) {
  .subHeadingDonate {
    font-size: 18px;
    margin: 10px;
    margin-left: 8px;
  }
  .text-overlay-blood-connect{
    transform: translate(-35%, -55%);
    font-size: 18px;
  }
  .questionImageStyling{
    width: 150px;
  }
  .donateImageContainerMain{
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: 30px;
    width: 80%;
    margin: auto;
  }
  .containerBloodImage{
    align-self: flex-start;
    bottom: 20px;
    left: 0px;
  }
  .WhatDoesBloodConnect {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .feedBackHeading {
    font-size: 24px;
  }
  .donateImageContainerDescription {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bloodImageProject {
    width: 90%;
    height: auto;
    max-width: 300px;
    margin-left: 0px;
  }

  .donate-para-text {
    width: 100%;
    text-align: left;
    font-size: 16px;
  }
  .WhatDoesBloodConnec {
    font-size: 24px;
  }

  .donate-para-2 {
    font-size: 20px;
    margin-left: 10px;
  }

  .donateDetailsHowWeDoIt {
    padding-left: 10px;
    margin-left: 8px;
    height: max-content;
    width: 60vw;
    background-color: #fbf3f3;
  }
  .sample-div {
    height: 300px;
  }

  .feedBackButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }
  .requestBloodButton {
    width: 100%;
    height: 60px;
  }

  .redBackgroundButton {
    width: 100%;
    margin-top: 16px;
    margin-left: 0px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .feedBackPara {
    font-size: 16px;
    width: 100%;
  }

  .feedBackContents {
    margin-left: 10px;
  }
  .feedBackOption {
    width: 100%;
    margin-bottom: 15px;
    text-align: left;
  }
  .feedBackParaOption {
    text-align: right;
  }

  .subHeadingDonate {
    text-align: left;
  }

  .howWeDoIt {
    text-align: center;
    margin-top: 40px;
    width: 100%;
    padding-bottom: 0px;
  }
  .main-blood-heading-donate {
    font-size: 28px;
    margin-bottom: 10px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 1000px) {
  .mainContainerDonateStyling {
    display: flex;
    flex-direction: column;
  }
  .contentsBloodConnectQuestion{
    width: 90%;
    padding-left: 25px;
  }

  .BloodcardContainerMain {
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
    min-height: 100px;
  }

  .donateHeadingMain {
    font-size: 24px;
  }

  .donateParaMain {
    font-size: 20px;
  }
  .howWeDoItHeading {
    text-align: center;
    margin-bottom: 25px;
    font-size: 20px;
  }
}

@media screen and (max-width: 576px) {
  .donateParaMain {
    font-size: 14px;
  }

  .donateHeadingMain {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px){
  .donateImageContainerMain{
    width: 100%;
    margin-top: 35px;
  }
}