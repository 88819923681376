
.rounded-box {
    background-color: #f0f4f9;
    border-radius: 10px;
    padding: 50px;
    width: 350px; 
    position: relative; /* Needed for button positioning */
    margin: auto;
    margin-top: 40px;
  }
  .rounded-box h2 {
    margin-bottom: 20px;
    font-family: "Cambria", "Helvetica Neue", sans-serif;;
  }

  .rounded-box p {
    font-family: "Calibri", "Helvetica Neue", sans-serif;;
  }

  .ok-button {
    margin: auto;
    background-color: #4c8df6; /* Green */
    border-radius: 10px;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: auto;
    cursor: pointer; /* Make it look like a button */
  }
  