.video-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 30px;
  /* 16:9 aspect ratio */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.arrange-about-us {
  width: 85%;
}

.about-para {
  text-align: left;
  line-height: 1.6;
  margin-top: 20px;
  font-size: 20px;
  width: 90%;
  font-family: "Sathoshi", sans-serif;
}

.about-heading {
  text-align: left;
  font-size: 30px;
}

.about-contents-container {
  padding: 40px;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  width: 95%;
}

.button-about {
  display: flex;
  width: 113%;
  height: 45px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--Corner-Radius-lg, 8px);
  border-width: 0px;
  color: var(--color-semantic-white-100, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  text-transform: uppercase;
  font-family: "Sathoshi", sans-serif;
  cursor: pointer;
}

.about-main-heading {
  font-size: 32px;
  padding-bottom: 0px;
  font-family: "Sathoshi", sans-serif;

  margin: 0px;
}

.large-screen-video {
  display: none;
}

.aboutStyling {
  border: 1px solid black;
  background: transparent;
}

.about-main-section-heading {
  padding: 0px;
}
@media screen and (min-width: 1000px) {
  .video-container {
    display: none;
  }

  .large-screen-video {
    display: block;
    flex-direction: column;
    justify-self: center;
    width: 100%;
  }

  .button-container {
    width: 94%;
    display: flex;
    margin-right: auto;
    font-family: "Roboto";
    row-gap: 10px;
    gap: 22px;
  }

  .arrange-video-large-screen {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 36px;
  }

  .about-main-heading {
    font-size: 45px;
    padding-bottom: 0px;
  }

  .about-para {
    width: 80%;
  }

  .about-heading {
    text-align: left;
  }

  .about-main-heading {
    text-align: left;
  }

  .about-contents-container {
    padding: 10px;
    /* margin-left: 10px; */
    padding-top: 0px;
    padding-left: 0px;
  }

  .arrange-about-us {
    display: flex;
    flex-direction: row;
    padding: 30px;
    /* width: 95%; */
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
    text-align: center;
    margin-top: 52px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .large-screen-video {
    display: block;
    width: 60%;
    justify-content: center;
    justify-self: center;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .arrange-about-us {
    padding-left: 50px;
  }

  .large-screen-video {
    display: block;
    width: 100%;
    margin-left: 75px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .arrange-about-us {
    padding-left: 10px;
  }

  .large-screen-video {
    display: block;
    width: 100%;
    margin-left: 75px;
  }
}

.about-us-home {
  background-color: #fffcf7;
}

.about-link {
  text-decoration: none;
  color: inherit;
  width: 140px;
  margin-right: 30px;
}

.button-vision {
  background: #fe6711;
}

.button-our-team {
  background: #617be3;
}

.button-success-stories {
  background: #36b0b8;
}

.mobileDevicesVideoContainer{
  margin-bottom: 35px;
}

@media screen and (max-width: 1440px) {
  .arrange-about-us {
    width: 95%;
  }
}

@media screen and (max-width: 1000px) {
  .arrange-about-us {
    width: 100%;
  }

  .button-container {
    display: flex;
    justify-content: flex-start;
    margin-left: 0px;
    margin-right: auto;
    margin-left: auto;
  }
  .about-contents-container {
    width: 100%;
    padding: 15px;
    padding-top: 25px;
    margin: auto;
  }
  .about-para {
    font-size: 14px;
  }
  .about-main-heading {
    text-align: center;
    margin-top: 50px;
  }
}
