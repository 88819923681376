.vision-and-mission-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  gap: 78px;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 30px;
}

@media screen and (max-width: 992px) {
  .vision-and-mission-container {
    flex-direction: column;
    gap: 40px;
    align-items: center;
    justify-content: center;
  }
}

.pledge-container {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #e9e7e7;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.14);
  width: 28%;
  margin: 0px;
}

@media screen and (max-width: 992px) {
  .pledge-container {
    width: 85%;
    padding: 0px;
  }
}

.our-vision-and-mission-cards-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  width: 50%;
}

@media screen and (max-width: 992px) {
  .our-vision-and-mission-cards-container {
    width: 85%;
  }
}

.our-vision-and-mission-card-container {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  border-radius: 16px;
  border: 1px solid #e9e7e7;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.14);
}

.our-vision-and-mission-card-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.our-vision-and-mission-card-heading {
  color: #000;
  text-align: justify;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 38.4px */
  font-family: "Sathoshi", sans-serif;
  margin-bottom: 0px;
}

.our-vision-and-mission-card-image {
  width: 41.6px;
  height: 39px;
}

.our-vision-and-mission-card-description {
  color: #535353;
  text-align: justify;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Sathoshi", sans-serif;
  margin-top: 0px;
}

.pledge-image {
  width: 92%;
  margin: auto;
}

@media screen and (max-width: 992px) {
  .pledge-image {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
  }
}
