.vision-and-mission-image-container {
  width: 100vw;
  background-size: cover;
  background-position: center;
  gap: 30px;
}

.vision-and-mission-image-container img {
  max-height: 100%;
  max-width: 100%;
}

.vision-and-mission-text-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  margin-top: 50px;
  width: 90%;
}

.vision-and-mission-heading {
  color: #000;
  font-family: "Roboto";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 992px) {
  .vision-and-mission-heading {
    font-size: 25px;
  }
}

.vision-and-mission-description {
  color: #7a7a7a;
  font-family: "Satoshi, sans-serif";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 992px) {
  .vision-and-mission-description {
    font-size: 16px;
  }
}

.about-main-container {
  background: #fffcf7;
}

.pledge-heading {
  font-family: "Satoshi, sans-serif";
  font-size: 24px;
  align-self: center;
}
