.arrangeTestimonialsRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}

@media screen and (max-width: 500px) {
  .arrangeTestimonialsRow {
    padding: 10px;
  }
}

.divQuotesContainer {
  width: 80px;
  position: relative;
  right: 15px;
}

@media screen and (max-width: 768px) {
  .divQuotesContainer {
    width: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .arrangeTestimonialsRow {
    width: 100%;
  }
}

.testimonials-title-large {
  text-align: right;
  font-size: 24px;
  margin-right: 25px;
  font-family: "Sathoshi", sans-serif;
  line-height: 40px;
}

@media screen and (max-width: 768px) {
  .testimonials-title-large {
    font-size: 20px;
  }
}

.contentsTestimonialLarge {
  margin-left: 50px;
  width: 80%;
}

.paraQuotesLarge {
  font-size: 24px;
  font-family: "Sathoshi", sans-serif;
}

@media screen and (max-width: 768px) {
  .paraQuotesLarge {
    font-size: 16px;
  }
}

.buttonStylingPreviousTestimonials {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.slider-container-large-screen {
  background-color: #dad6d3;
  padding: 10px;
  padding-top: 30px;
  font-family: "Roboto";
}
.testimonials-heading-large {
  font-size: 40px;
  text-align: center;
  font-family: "Sathoshi", sans-serif;
  margin-top: 10px;
  margin-bottom: 0px;
}

.thumbs-wrapper {
  margin: 0px;
}
.axis-vertical {
  margin: 0px;
}

@media screen and (max-width: 768px) {
  .testimonials-heading-large {
    font-size: 40px;
    text-align: center;
  }
}

.buttonStylingNextTestimonials {
  background-color: transparent;
  border: none;
  margin-right: 25px;
  cursor: pointer;
}

.testimonialImageLarge {
  border-radius: 16px;
  height: 225px;
  width: 225px;
}

@media screen and (max-width: 768px) {
  .testimonialImageLarge {
    height: 150px;
    width: 120px;
  }
}

.paraQuotesLarge {
  text-align: left;
  line-height: 1.8;
  margin-top: 25px;
}
