.footer-main-container {
  display: flex;
  /* width: 100%; */
  padding: var(--Spacing-none, 72px) var(--Spacing-none, 110px);
  padding-left: 0;
  padding-right: 0px;
  justify-content: center;
  align-items: center;
  background: #23211f;
  padding-bottom: 0px;
}

.aswa-logo {
  width: 200px;
}

@media screen and (max-width: 992px) {
  .aswa-logo {
    width: 150px;
  }
}

@media screen and (max-width: 992px) {
  .footer-main-container {
    padding: var(--Spacing-none, 72px) var(--Spacing-none, 30px);
    padding-bottom: 0px;
    gap: 0px;
  }
}

.footer-sub-container {
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
}

.footer-section-first-container {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  /* gap: inherit; */
  gap: 30px;
}

@media screen and (max-width: 992px) {
  .footer-section-first-container {
    flex-direction: column;
    align-items: flex-start;
    column-gap: 10px;
    row-gap: 0px;
  }
}

.logo-and-gmail-container {
  display: flex;
  width: 30%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-none, 68px);
}

@media screen and (max-width: 992px) {
  .logo-and-gmail-container {
    width: 100%;
    gap: 30px;
  }
}

.mail-container {
  display: flex;
  align-items: center;
  gap: 22px;
}

@media screen and (max-width: 772px) {
  .mail-container {
    gap: 4px;
  }
}

.mail {
  color: var(--color-semantic-white-100, #fff);
  font-family: "Sathoshi", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 5.024px;
}

.address-container {
  display: flex;
  width: 30%;
  /* padding: var(--Spacing-none, 0px) var(--Spacing-none, 0.33px)
    var(--Spacing-none, 9px) var(--Spacing-none, 0px); */
  flex-direction: column;
  align-items: flex-start;
  /* gap: var(--Spacing-none, 20px); */
}

@media screen and (max-width: 992px) {
  .address-container {
    width: 100%;
    /* gap: 1px; */
  }
}

.address-heading-container {
  display: flex;
  gap: 4px;
  font-size: 24px;
  font-family: "Sathoshi", sans-serif;
  align-items: center;
}

.address-heading {
  color: var(--color-semantic-white-100, #fff);
  font-family: "Sathoshi", sans-serif;
  font-size: 24px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 24px; /* 100% */
}

@media screen and (max-width: 992px) {
  .address-heading {
    font-size: 19px;
    margin-bottom: 0px;
  }
}

.address-description {
  color: var(--color-semantic-white-100, #fff);
  font-family: "Sathoshi", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5; /* 125.608% */
  text-align: left;
  padding-left: 36px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .address-description {
    font-size: 16px;
  }
}

.horizontal-line-container {
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  /* margin-top: 0px; */
}

.follow-us-section-container {
  display: flex;
  padding: var(--Spacing-none, 0px) var(--Spacing-none, 0.394px)
    var(--Spacing-none, 38.869px) var(--Spacing-none, 0px);
  justify-content: space-between;
  align-items: flex-end;
  gap: var(--Spacing-none, 256.2px);
  flex-shrink: 0;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .follow-us-section-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    gap: 20px;
    padding-bottom: 16px;
  }
}

.follow-us-icons {
  display: flex;
  gap: 40px; /* Remove gap */
}

.quotation {
  color: var(--color-semantic-white-100, #fff);
  font-family: "Sathoshi", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0; /* Remove margin top */
}

@media screen and (max-width: 992px) {
  .quotation {
    text-align: left;
    font-size: 16px;
  }
}

.reach-us {
  color: var(--color-semantic-white-100, #fff);
  font-family: "Sathoshi", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  /* margin-bottom: 20px; */
  line-height: 28px; /* 155.556% */
  margin-bottom: 0px;
  margin-top: 0px;
}

@media screen and (max-width: 992px) {
  .reach-us {
    text-align: left;
    font-size: 22px;
    margin-bottom: 0px;
  }
  .mail {
    font-size: 16px;
  }

  .footer-main-container {
    padding-top: 30px;
    padding-left: 0px;
  }
}

.reach-us-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.locationImageFooter {
  width: 30px;
  height: 68px;
}

@media screen and (max-width: 380px) {
  .aswa-logo {
    width: 150px;
  }
}

.locationImageFooter1 {
  height: 30px;
  width: 30px;
}
