.success-stories-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
  width: 90%;
  margin: auto;
}

.success-stories-heading {
  color: #000;
  font-family: 'Sathoshi', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .success-stories-heading {
    font-size: 22px;
    line-height: 32px;
    margin-top: 15px;
  }
}

.success-stories-testimonials-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  margin: auto;
  background: #f0ece9;
  padding: 30px;
  border-radius: 16px;
  margin-bottom: 45px;
}

@media screen and (max-width: 992px) {
  .success-stories-testimonials-container {
    flex-direction: column;
    gap: 0px;
  }
}

.success-stories-testimonial-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
}

@media screen and (max-width: 992px) {
  .success-stories-testimonial-image-container {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .success-stories-testimonial-image {
    width: 173px;
  }
}

.success-stories-testimonial-text-container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .success-stories-testimonial-text-container {
    align-self: center;
    flex-direction: column-reverse;
    justify-content: center;
  }
}

.success-stories-testimonial-description {
  color: #000;
  text-align: justify;
  font-family: 'Sathoshi', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* text-transform: capitalize; */
  text-align: left;
}

@media screen and (max-width: 992px) {
  .success-stories-testimonial-description {
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    margin-top: 0px;
  }
}

.success-stories-testimonial-name {
  color: #000;
  font-family: 'Sathoshi', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  width: 60%;
  height: 125px;
  flex-shrink: 0;
  align-self: flex-end;
  text-align: left;
  padding-top: 30px;
}

@media screen and (max-width: 992px) {
  .success-stories-testimonial-name {
    font-size: 16px;
    line-height: 22px;
    align-self: center;
    width: 100%;
    text-align: center;
    line-height: normal;
    margin-bottom: 0px;
    /* height: 80px; */
    height: 112px;
  }
}

.testimonials-container1 {
  margin-bottom: 40px;
}
