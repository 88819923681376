.visionandmission-navbar-container {
  display: flex;
  width: 90%;
  height: 60px;
  padding: Spacing/3xs;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: var(--Corner-Radius-lg, 8px);
  border: 1px solid var(--Color-Border-neutral---Subtle, #cbd5e1);
  background: var(--Color-Action-neutral-subtle-normal, #f1f5f9);
  margin: auto;
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .visionandmission-navbar-container {
    display: flex;
    width: 90%;
    height: 45px;
    padding: Spacing/3xs;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border-radius: var(--Corner-Radius-lg, 8px);
    border: 1px solid var(--Color-Border-neutral---Subtle, #cbd5e1);
    background: var(--Color-Action-neutral-subtle-normal, #f1f5f9);
  }
}

.visionandmission-navbar-button {
  display: flex;
  width: 20%;
  height: 40px;
  padding: var(--Spacing-2xs, 8px) var(--Spacing-xs, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-2xs, 8px);
  flex-shrink: 0;
  border-radius: var(--Corner-Radius-lg, 8px);
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    var(--Color-Action-neutral-subtle-normal, #f1f5f9);
  border-width: 0px;
  cursor: pointer;
  font-family: 'Sathoshi', sans-serif;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .visionandmission-navbar-button {
    font-size: 9px;
    height: 28px;
    width: 28%;
    padding: 0px;
  }
}

.visionandmission-navbar-button.active {
  background: #36b0b8;
  color: #fff;
  font-weight: bold;
}
