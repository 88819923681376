.OurProjects1-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 45px;
  background: #f1eeec;
  padding-bottom: 83px;
}

@media screen and (max-width: 998px) {
  .OurProjects1-card-container {
    padding: 20px 20px;
  }
}

.heading-container {
  margin-top: 10px;
  align-self: center;
}

.OurProjects1-main-heading {
  align-self: center;
  font-size: 60px;
  margin-bottom: 0px;
  font-family: "Sathoshi", sans-serif;
}

@media screen and (max-width: 998px) {
  .OurProjects1-main-heading {
    font-size: 30px;
  }
}

.OurProjects1-sub-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
}

.what-we-did-card {
  display: flex;
  width: 50%;
  height: 298.097px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6.731px;
  flex-shrink: 0;
  background: #202d33;
}

@media screen and (max-width: 998px) {
  .what-we-did-card {
    width: 100%;
  }
}

.our-projects-image {
  width: 25%;
  height: 298.097px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 568px) {
  .our-projects-image {
    width: 100%;
  }
}

@media screen and (min-width: 569px) and (max-width: 998px) {
  .our-projects-image {
    width: 50%;
  }
}

.donate-card-container-home {
  width: 25%;
  flex-shrink: 0;
  background: #1e9ab6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 568px) {
  .donate-card-container-home {
    width: 100%;
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 569px) and (max-width: 998px) {
  .donate-card-container-home {
    width: 50%;
  }
}

.donate-card-text-home {
  color: var(--color-semantic-white-100, #fff);
  font-size: 16px;
  font-family: "Sathoshi", sans-serif;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  padding: 28px;
  padding-top: 0px;
  padding-bottom: 20px;
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.donate-card-author-home {
  color: white;
  text-align: right;
  width: 80%;
  font-size: 18px;
  font-family: "Sathoshi", sans-serif;
  font-weight: bold;
  margin-top: 0px;
}

@media screen and (max-width: 998px) {
  .donate-card-text-home {
    font-size: 15px;
    padding-top: 30px;
  }
}

.what-we-did-card-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.673px;
  margin-left: 54px;
}

@media screen and (max-width: 998px) {
  .what-we-did-card-text-container {
    margin-left: 38px;
  }
}

.what-we-did-heading {
  color: #1e9ab6;
  font-family: "Sathoshi", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 108.3%;
  margin-bottom: 0px;
}

@media screen and (max-width: 998px) {
  .what-we-did-heading {
    font-size: 25px;
  }
}

.what-we-did-description {
  font-family: "Sathoshi", sans-serif;
  color: var(--color-semantic-white-100, #fff);
  text-align: left;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  margin-top: 20px;
}

@media screen and (max-width: 998px) {
  .what-we-did-description {
    font-size: 25px;
  }
}

.our-projects1-image-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.144px;
  padding: 20.892px 21.784px;
  opacity: 1; /* Make the text container always visible */
  transform: translateY(0); /* Reset the transform property */
  transition: none; /* Remove any transition effect */
}

/* Remove the hover property */
.our-projects-image:hover .our-projects1-image-text-container {
  /* These properties can be removed or left empty */
}

.our-projects1-image-heading {
  color: var(--color-semantic-white-100, #fff);
  font-family: "Sathoshi", sans-serif;
  font-size: 24px;
  text-align: left;
  font-style: normal;
  line-height: 120%;
  margin-bottom: 0px;
}

.our-projects1-image-button {
  display: flex;
  padding: 10.892px 21.784px;
  justify-content: center;
  align-items: center;
  gap: 6.808px;
  border-radius: 23.146px;
  background: #fe6711;
  border-width: 0px;
  cursor: pointer;
  color: var(--color-semantic-white-100, #fff);
  /* font-family: "Satoshi, sans-serif"; */
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ourprojectscard-link {
  text-decoration: none;
  color: inherit;
}
