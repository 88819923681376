.impact-reports-image-container {
  width: 100%;
  height: 40vh;
}

.impact-reports-image {
  width: 100%;
  height: 100%;
}

.impact-reports-cards-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  width: 90%;
  margin: auto;
}

@media screen and (max-width: 992px) {
  .impact-reports-cards-container {
    width: 98%;
    padding: 0px;
  }
}

.impact-reports-first-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
}

.impact-reports-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}

.impact-reports-card1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;
  flex-wrap: wrap;
}

.impact-reports-card-heading {
  color: #000;
  font-family: "Segoe UI","Sathoshi", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
  text-align: left;
}

@media screen and (max-width: 992px) {
  .impact-reports-card-heading {
    font-size: 24px;
    align-self: center;
    text-align: center;
  }
}

.impact-reports-card-description-container1 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.impact-reports-card-description-container {
  display: flex;
  width: 30%;
  padding: 11px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid #d9dade;
  background: #fff;
}

@media screen and (max-width: 992px) {
  .impact-reports-card-description-container {
    width: 100%;
  }
}

.impact-reports-count {
  color: #5843b4;
  font-family: Calibri, "Sathoshi", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 550;
  line-height: 24px;
  margin: 0px;
  padding: 10px;
}

@media screen and (max-width: 992px) {
  .impact-reports-count {
    font-size: 20px;
    padding: 5px;
  }
}

.impact-reports-card-description {
  color: #5f5f5f;
  font-family: "Segoe UI", "Sathoshi", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin: 0px;
  padding: 10px;
  text-align: left;
}

@media screen and (max-width: 992px) {
  .impact-reports-card-description {
    font-size: 14px;
    padding: 5px;
  }
}

.environment-description-card {
  display: flex;
  width: 100%;
  padding: 11px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid #d9dade;
  background: #fff;
  height: 176px;
}

@media screen and (max-width: 992px) {
  .environment-description-card {
    width: 90%;
    padding: 10px;
  }
}

.environment-impact-reports-card {
  width: 30%;
}

@media screen and (max-width: 992px) {
  .environment-impact-reports-card {
    width: 100%;
    margin-bottom: 30px;
  }
}
