.higher-education-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 39px;
  margin-bottom: 20px;
  width: 95%;
  margin: auto;
}

.backgroundHigherEducation {
  border-radius: 16px;
  background: #f1eeec;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.higher-education-text {
  color: #000;
  text-align: center;
  font-family: "Sathoshi", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 768px) {
  .higher-education-text {
    color: #000;
    text-align: center;
    font-family: "Sathoshi", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.2px;
  }
}

.higher-education-description {
  color: #000;
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  font-family: "Sathoshi", sans-serif;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .higher-education-description {
    color: #000;
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin: 0px;
    width: 100%;
  }
}

.higher-programs-container {
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
  /* padding-left: 20px; */
  gap: 30px;
}

@media screen and (max-width: 768px) {
  .higher-programs-container {
    /* gap: 15px; */
    /* padding-left: 56px; */
  }
}

.higher-education-programs-heading {
  color: #000;
  text-align: left;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: "Sathoshi", sans-serif;
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .higher-education-programs-heading {
    font-size: 16px;
    line-height: 32px;
  }
}

.program-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 10px;
  font-size: 32px;
  line-height: 1px;
  padding-left: 8px;
}

@media screen and (max-width: 768px) {
  .program-list {
    gap: 0px;
    font-size: 16px;
  }
}

/* .program-item {
  color: #000;
  text-align: justify;
  font-family: Inter;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 187.5% */
/* } */

li {
  color: #000;
  text-align: justify;
  font-family: "Satoshi, sans-serif";
  font-style: normal;
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 60px; /* 187.5% */
  letter-spacing: 0.2px;
}

.program-item-description-education {
  padding: 0px;
  margin: 10px;
  padding-left: 20px;
  font-size: 26px;
}

@media screen and (max-width: 768px) {
  .program-item-description-education {
    font-size: 14px;
    padding-left: 3px;
    line-height: 24px;
  }
}

@media screen and (max-width: 768px) {
  li {
    font-size: 16px;
    line-height: 35px;
  }
}

.know-more-button {
  display: flex;
  width: 332px;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 18px;
  background: #36b0b8;
  color: var(--color-semantic-white-100, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  text-transform: uppercase;
  border-width: 0px;
  align-self: center;
  font-family: "Sathoshi", sans-serif;
  cursor: pointer;
}

.knowMoreEducationLink {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .know-more-button {
    font-size: 12px;
    height: 35px;
    width: auto;
  }
}

.higher-education-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1eeec;
  padding: 30px;
  border-radius: 8px;
  gap: 30px;
}

@media screen and (max-width: 768px) {
  .arrow-image1 {
    height: 12px;
  }
}
