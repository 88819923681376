.education-navbar-container {
  display: flex;
  width: 80%;
  height: 79px;
  padding: Spacing/3xs;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: var(--Corner-Radius-lg, 8px);
  border: 1px solid var(--Color-Border-neutral---Subtle, #cbd5e1);
  background: var(--Color-Action-neutral-subtle-normal, #f1f5f9);
}

@media screen and (max-width: 768px) {
  .education-navbar-container {
    display: flex;
    width: 90%;
    height: 50px;
    padding: Spacing/3xs;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border-radius: var(--Corner-Radius-lg, 8px);
    border: 1px solid var(--Color-Border-neutral---Subtle, #cbd5e1);
    background: var(--Color-Action-neutral-subtle-normal, #f1f5f9);
  }
}

.education-navbar-button {
  font-family: 'Sathoshi', sans-serif;
  display: flex;
  width: 48%;
  height: 50px;
  padding: var(--Spacing-2xs, 8px) var(--Spacing-xs, 12px);
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: var(--Corner-Radius-lg, 8px);
  background: var(--Color-Background-default, #fff);
  border-width: 0px;
  /* Shadow/sm */
  box-shadow: 0px 1px 3px 0px rgba(15, 23, 42, 0.12);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  color: var(--Color-Text-neutral-subtle, #475569);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .education-navbar-button {
    font-size: small;
    height: 35px;
    width: 45%;
    padding: 0px;
  }
}

.active {
  border-radius: var(--Corner-Radius-lg, 8px);
  background: #ffffff; /* Default background color */
  /* Shadow/sm */
  box-shadow: 0px 1px 3px 0px rgba(15, 23, 42, 0.12);
  background: #36b0b8; /* Active background color */
  color: #ffffff;
}
