.marquee-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 40px;
  background-color: #fffcf7;
  gap: 30px;
}

.csrPartnersHeading {
  color: black;
  font-size: 48px;
  text-align: center;
  font-family: "Sathoshi", sans-serif;
  align-self: center;
  margin-top: 30px;
}

@media screen and (max-width: 992px) {
  .csrPartnersHeading {
    font-size: 30px;
    padding-bottom: 52px;
  }
}

.marquee {
  display: flex;
  white-space: nowrap; /* Ensures images stay in a single line */
  animation: marquee 20s linear infinite;
}

.csr-image {
  height: 105px;
  margin-right: 30px;
  flex-shrink: 0;
  transition: transform 0.3s ease;
}

.csr-image:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .csr-image {
    height: 115px;
    margin-right: 10px;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.csr-heading {
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: "Satoshi, sans-serif";
}

@media screen and (max-width: 768px) {
  .csr-heading {
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
