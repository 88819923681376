.active {
  background-color: #36b0b8;
}

/* .logoResizeHomePage {
  width: 280px;
  align-self: center;
} */

/* nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.26);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
  padding: 0 20px;
  box-sizing: border-box;
  overflow-x: hidden;
  height: 91px;
} */

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: rgba(0, 0, 0, 0.26); /* Initial transparent background */
  background: linear-gradient(to right, #FDFCFB,#e2d1c32a);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
  padding: 0 20px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 91px;
  transition: background-color 0.5s; /* Smooth transition */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1082px) {
  nav {
    height: auto;
    background-color: rgb(250, 245, 241) !important;
  }
}

.listNav {
  font-size: 17px;
  align-self: center;
  padding-top: 10px;
  color: #ffffff;
  cursor: pointer;
  height: 40px;
  padding: 4px;
  display: flex;
  align-items: center;
  transform: scale(1);
  transition: transform 0.3s ease;
}

@media (min-width: 1082px) and (max-width: 1300px) {
  .listNav {
    padding: 0px;
  }
}

.listNav:hover {
  background-color: #f7e5d7;
  border: 1px solid #f7e5d7;
  border-radius: 5px;
  color: #ffffff;
  transform: scale(1.1);
  /* font-size: 19px; */
  font-weight: 500;
}

.aboutUsButtonNav {
  width: 142px;
  text-align: center;
  font-family: "Sathoshi", sans-serif;
  color: #ffffff;
}

@media (max-width: 1082px) {
  .aboutUsButtonNav {
    color: black !important;
  }
}

.onMobileDevices {
  display: none;
}

nav .title {
  font-size: 1.5rem;
  margin: 0px;
  font-weight: bold;
  text-decoration: none;

  color: #202d33;
}

nav ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
  color: #202d33;
  padding-left: 0px;
}

nav ul li {
  list-style: none;
  color: #202d33;
}

nav ul li a {
  display: block;
  text-decoration: none;
  padding: 0.5rem;
  margin: 0;
  border-radius: 0.5rem;
}

.linkStyling {
  color: #ffffff;
  font-family: "Sathoshi", sans-serif;
  color: var(--color-semantic-white-100, #fff);
}

.joinWithUs {
  width: 150px;
  padding: 15px 10px;
  gap: 10px;
  color: black;
  font-family: Sathoshi;
  font-weight: 600;
  font-size: 13px;
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 3px;
  color: var(--color-semantic-white-100, #fff);
  font-family: "Sathoshi", sans-serif;
  transform: scale(1);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.joinWithUs:hover {
  background-color: #f7e5d7;
  border: 1px solid #f7e5d7;
  transform: scale(1.1);
  color: #ffffff;
}
.horizontal-line-nav-bar {
  border: 1px black solid;
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
  cursor: pointer;
}

nav .menu span {
  height: 0.2rem;
  width: 90%;
  background-color: white;
  border-radius: 0.1rem;
}

@media (max-width: 1082px) {
  nav .menu span {
    background-color: black;
  }
}

.donateStyling {
  width: 150px;
  padding: 15px 10px;
  gap: 10px;
  color: white;
  font-family: "Sathoshi";
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #000;
  background-color: #fe6711;
  border-width: 0px;
  border-radius: 3px;
  cursor: pointer;
  font-family: "Sathoshi", sans-serif;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.donateStyling:hover {
  background-color: #fe6711;
  transform: scale(1.1); /* Increase the scale to make it larger */
}

.specialLink {
  font-size: 17px;
  padding: 0;
}

@media (max-width: 1170px) {
  .donateStyling {
    width: 120px;
  }
  .specialLink {
    text-align: center;
  }

  .logoResizeHomePage {
    width: 185px;
  }
}

@media (min-width: 1080px) and (max-width: 1320px) {
  .specialLink {
    display: none;
  }
}

@media (max-width: 1080px) {
  nav .menu {
    display: flex;
    align-items: center;
    z-index: 1;
    margin-right: 15px;
    margin-top: 16px;
  }

  .borderLineNav {
    border-bottom: 2px solid rgb(126, 126, 126);
  }

  .specialLink {
    padding: 8px;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .donateStyling {
    background-color: #fe6711;
    padding: 13px;
    z-index: 20;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }
  .listNav {
    justify-content: center;
    color: black;
  }

  .mobileDeviceLogoResize {
    width: 160px;
  }

  .title {
    display: none;
  }

  .titleMobile {
    display: block;
  }

  nav ul.open {
    display: flex;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    background: rgba(15, 23, 42, 0.12);
    /* border-top: 10px solid; */
    position: relative;
    width: 100%;
    bottom: 15px;
    /* margin-top: 150px; */
    /* z-index: 1; */
  }

  .onMobileDevices {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80vw;
    justify-content: space-between;
  }

  nav ul li {
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
  }

  .donateStyling {
    display: none;
  }

  .donateMobile {
    display: block;
    width: 100px;
  }

  .linkStyling {
    text-decoration: none;
    padding: 6px;
    color: #ffffff;
  }

  .joinWithUs {
    width: 135px;
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    margin-top: 15px;
    color: #ffffff;
    border: 2px solid #ffffff;
  }

  .mobileDonate {
    display: none;
  }
}

@media (min-width: 992px) {
  .logoResizeHomePage {
    width: 165px;
    padding-top: 3px;
  }
  nav {
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (max-width: 400px) {
  .mobileDeviceLogoResize {
    width: 125px;
  }

  .mobileDevicesNavConatainer {
    padding: 15px;
    display: flex;
    align-items: center;
    /* flex-grow: 1; */
  }
}

.crossIcon {
  cursor: pointer;
  color: #ffff;
}

nav ul li a:hover {
  color: #fbf3ec; /* Hover color */
}

@media (max-width: 1082px) {
  .crossIcon path {
    fill: black;
  }
}
