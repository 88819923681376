.imageStylingHomeVisits{
    width: 60%;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.imageSrcHome{
    border-radius: 30px;
    background-position: center;
}



@media screen and (min-width: 1024px) {
    .imageStylingHomeVisits{
        width: 30%;
        max-width: 500px;
    }
    .homePreviousButton{
        margin-left: 280px ;
    }
    .homeNextButton{
        margin-right: 280px;
    }
}