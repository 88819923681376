@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.AppHomeVisits {
  width: 70%;
  margin: 10rem auto;
  margin-bottom: 0px;
  height: 570px;
  margin-top: 30px;
}

.photoGalleryHeadingLaptop{
  margin-bottom: 50px;
}

.slideHomeVisits img {
  width: 25rem;
  margin: 0 auto;
}

.slideHomeVisits {
  transform: scale(0.2);
  transition: transform 300ms;
  opacity: 0.5;
}

.activeSlideHomeVisits {
  transform: scale(1.1);
  opacity: 1;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
  font-size: 32px;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

.homeVisitsLargeScreenImage{
  width: 1000px;
  border-radius: 50px;
}

@media (max-width: 1024px){
  .AppHomeVisits{
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1440px){
  .AppHomeVisits{
    width: 90%;
  }
}