.cards-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
}

.card-app-container {
  display: flex;
  align-items: flex-start;
  gap: 48px;
  width: 90%;
  justify-content: center;
  margin: auto;
  flex-direction: row;
}

@media screen and (max-width: 756px) {
  .card-app-container {
    gap: 5px;
  }
}

.card-app-container:nth-child(2) {
  flex-direction: row;
}

@media screen and (max-width: 756px) {
  .card-app-container:nth-child(2) {
    flex-direction: column;
    align-items: center; /* This will center the items vertically */
    gap: 10;
  }
}

.card-app-container:nth-child(3) {
  flex-direction: row-reverse;
}

@media screen and (max-width: 756px) {
  .card-app-container:nth-child(3) {
    flex-direction: column;
    align-items: center; /* This will center the items vertically */
    gap: 10;
  }
}

.card-app-container:nth-child(4) {
  flex-direction: row;
}

@media screen and (max-width: 756px) {
  .card-app-container:nth-child(4) {
    flex-direction: column;
    align-items: center; /* This will center the items vertically */
    gap: 10;
  }
}

.card-app-container:nth-child(5) {
  flex-direction: row-reverse;
}

@media screen and (max-width: 756px) {
  .card-app-container:nth-child(5) {
    flex-direction: column;
    align-items: center; /* This will center the items vertically */
    gap: 10;
  }
}

@media screen and (max-width: 756px) {
  .card-app-container:nth-child(6) {
    flex-direction: column;
    align-items: center; /* This will center the items vertically */
    gap: 10;
  }
}

.card-image {
  max-width: 50%;
  height: auto;
}

@media screen and (max-width: 756px) {
  .card-image {
    max-width: 100%;
  }
}

.image {
  height: 402px;
}

.card-description-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  width: 50%;
}

@media screen and (max-width: 756px) {
  .card-description-container {
    width: 100%;
  }
}

.card-description-heading {
  color: #000;
  font-family: "Sathoshi", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
  padding-bottom: 0px;
  margin-bottom: 10px;
}

@media screen and (max-width: 992px) {
  .card-description-heading {
    font-size: 16px;
  }
}

.card-description {
  color: #000;
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  overflow: hidden;
  font-family: "Sathoshi", sans-serif;
}

@media screen and (max-width: 992px) {
  .card-description {
    font-size: 12px;
    line-height: 1.5;
  }
}

.school-education-images {
  width: 100%;
  border-radius: 10px;
}

.education-text-container {
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  margin: auto;
  background: #f1eeec;
  padding: 30px;
  border-radius: 8px;
  gap: 30px;
}

@media screen and (max-width: 992px) {
  .education-text-container {
    padding-top: 0px;
    gap: 20px;
  }
}

.education-text {
  color: #000;
  text-align: center;
  font-family: "Sathoshi", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  padding-top: 15px;
  margin-top: 0px;
}

@media screen and (max-width: 992px) {
  .education-text {
    font-size: 24px;
  }
}

.education-description {
  color: #000;
  text-align: justify;
  font-family: "Sathoshi", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.2px;
  width: 95%;
}

@media screen and (max-width: 992px) {
  .education-description {
    font-size: 12px;
    line-height: 30px;
  }
}
