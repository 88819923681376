.customCarousel6 {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: url("/public/Images/impactreportbanner.jpg");
  position: relative;
  z-index: 1001;
}

.customCarousel6 h1 {
  font-size: 74px;
  color: rgb(184, 141, 241);
  word-break: normal;
  width: 60%;
  text-align: left;
  line-height: 1.5;
  padding-left: 75px;
  font-family: "Sathoshi", sans-serif;
  margin-bottom: 5px;
}

.customCarousel1 {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: url("/public/Images/bookBanner.jpg");
  position: relative;
  z-index: 1001;
}

Carousel {
  position: relative;
  z-index: 1001; /* Higher z-index for the carousel */
}

.customCarousel4 {
  position: relative; /* Position relative to contain absolutely positioned pseudo-element */
  width: 100%; /* Adjust according to your layout */
  height: 100vh; /* Adjust according to your layout */
  background-image: url("/public/Images/successStories.png"); /* Set your background image */
  background-size: cover;
}

.customCarousel4::before {
  content: ""; /* Necessary for pseudo-elements */
  position: absolute; /* Position absolutely within the container */
  top: 0;
  left: 0;
  width: 80%; /* Same as container width */
  height: 100vh; /* Same as container height */

  /* background: linear-gradient(to right, rgba(114, 151, 160, 0.5), rgba(201, 236, 244, 0.1)); */
  background: linear-gradient(
    to right,
    rgba(114, 151, 160, 1),
    rgba(114, 151, 160, 0)
  );
  background-clip: content-box;
  /* background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)); Adjust gradient colors and transparency */
}

.bannerImageStylingBanner {
}

.bannerImageCOntainer {
  align-self: center;
}

.carousel4SideHeading {
  z-index: 2;
}

.buttonUpp {
  z-index: 2;
}

.customCarousel2 {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: url("/public/Images/donateBanner.jpg");
}
.customCarousel5 {
  height: 100vh;
  width: 100%;
  background-color: #10275d;
  display: flex;
  flex-direction: column;
}

.YearsImage {
  height: 80vh;
}

.decorationImage {
  width: 150px;
  height: 100px;
}

.years16Container {
  width: 20%;
}

.childrenImageContainer {
  width: 50vw;
  margin-top: 50px;
}

.arrangeYearsBannerContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  justify-self: center;
  height: 100%;
}
.years16Image {
  width: 150px;
}

.arrangeDecoration {
  display: flex;
}

.decorationContainer {
  width: 50%;
  display: none;
}

.celebrationsContainer {
  width: 50%;
}

.customCarousel3 {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: url("/public/Images/environmentBanner.jpg");
}

.knowMoreCarousel {
  background-color: #ffb800;
  color: black;
  border: none;
  font-weight: bold;
  font-size: 18px;
  padding: 15px;
  margin-left: 75px;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  text-align: center;
  width: 220px;
  border-radius: 12px;
  cursor: pointer;
}

.carouselHeading {
  font-size: 74px;
  color: white;
  word-break: normal;
  width: 60%;
  text-align: left;
  line-height: 1.5;
  padding-left: 75px;
  font-family: "Sathoshi", sans-serif;
  margin-bottom: 5px;
}

.carouselDescription {
  font-size: 24px;
  color: white;
  width: 50%;
  text-align: left;
  padding-left: 75px;
  line-height: 1.5;
  font-family: "Sathoshi", sans-serif;
  margin-top: 0px;
}

.secondBannerHeading {
  width: 25%;
  line-height: 1.2;
  color: #fe3d33;
  padding-bottom: 25px;
}

.secondBannerSideHeading {
  color: black;
  width: 45%;
  font-size: 24px;
  line-height: 1.2;
}

.carousel3Heading {
  width: 30%;
  line-height: 1.3;
}
.carousel4Heading {
  z-index: 2;
  width: 35%;
  font-size: 60px;
}

.carousel3SideHeading {
  width: 40%;
  margin-top: 10px;
}

.carousel-status {
  display: none !important;
}

.carousel .control-arrow {
  /* Your custom styles here */
}

.carousel .control-dots {
  display: none;
}

.buttonStylingPrevious {
  background-color: transparent;
  border: none;
  position: absolute;
  width: 30px;
  margin-left: 15px;
  cursor: pointer;
  z-index: 1;
}

.buttonStylingNext {
  background-color: transparent;
  border: none;
  width: 30px;
  right: 15px;
  position: absolute;
  margin-left: 96%;
  cursor: pointer;
}

/* Override styles for left arrow */
.carousel .control-arrow.control-prev {
  /* Your custom styles here */
}

.carousel-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Override styles for right arrow */
.carousel .control-arrow.control-next {
  /* Your custom styles here */
}

.customCarouselTextContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.arrangeCarouselContainerMobileBanner {
  display: none;
}

.customCarousel5Container {
  margin-top: auto;
  margin-bottom: auto;
}

@media screen and (max-width: 1000px) {
  .customCarousel1 {
    background-image: url("/public/Images/educationBannerMobile.jpg");
    height: 100vh;
    width: 100%;
    background-size: center;
    background-size: cover;
  }

  .customCarousel2 {
    background-image: url("/public/Images/bloodImageDonateMobile.jpg");
    height: 100vh;
    width: 100%;
    background-size: center;
    background-size: cover;
  }

  .customCarousel4 {
    height: 100vh;
    width: 100%;

    background-image: url("/public/Images/successStoriesMobileBanner.png");
  }

  .carousel4SideHeading {
    font-weight: 500;
    font-size: 26px;
  }

  .customCarousel3 {
    height: 100vh;
    width: 100%;
  }

  .carouselHeading {
    font-size: 32px;
    width: 90%;
    padding-left: 30px;
  }

  .customCarousel6 h1 {
    font-size: 32px;
    width: 90%;
    padding-left: 30px;
  }

  .carouselDescription {
    width: 90%;
    padding-left: 30px;
    margin-top: 20px;
  }

  .knowMoreCarousel {
    width: 130px;
    font-size: 15px;
    margin-left: 30px;
  }

  .customCarouselTextContainer {
    padding-top: 100px;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 1800px) {
  .childrenImageContainer {
    width: 60vw;
  }

  .years16Container {
    width: 25%;
  }

  .arrangeDecoration {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .arrangeYearsBannerContainer {
    display: none;
  }
  .years16ContainerMobile {
    width: 30%;
    max-width: 200px;
    height: auto;
  }
  .arrangeCarouselContainerMobileBanner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .childrenImageContainer {
    width: 60vw;
  }

  .secondDecoration {
    display: none;
  }
  .childrenImageContainerMobile {
    width: 90%;
    margin-top: 50px;
    max-width: 450px;
  }

  .customCarousel5Container{
    margin-top: auto;
  }
}

.arrow-button {
  cursor: pointer;
}

.carousel-container {
  /* Adjust positioning and dimensions of the carousel */
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: 5%;
  height: 100vh;
}


@media  screen and (max-width: 500px) {
  .years16ContainerMobile{
    width: 60%;
    max-width: 500px;
  }
}