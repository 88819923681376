* {
  font-family: "Sathoshi", sans-serif;
}

.annamparabrahmam-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.homeVisits-image {
  height: 300px;
  width: 100vw;
}

.homeVisitsContainerContents {
  font-family: "Sathoshi", sans-serif;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  background-color: #f0ece9;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 16px;
  gap: 30px;
}

.ourActivitiesHeading {
  text-align: left;
  padding-left: 10px;
  font-size: 48px;
  font-family: "Sathoshi", sans-serif;
  margin-bottom: 0px;
}

.listStyleItemHomeVisits {
  display: flex;
  padding: 10px;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 30px;
}

.arrowImageContainer {
  align-self: flex-start;
}

@media screen and (max-width: 768px) {
  .arrowImageContainer {
    margin-top: 4px;
  }
  .homeVisits-image {
    height: 200px;
  }
}

.arrangeHomeVistsArrange {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  gap: 30px;
}

.subHeadingHomeVisits {
  font-size: 20px;
  text-align: left;
  padding-left: 20px;
  letter-spacing: 1px;
  line-height: 1.5;
  width: 90%;
  font-family: "Sathoshi", sans-serif;
}

.homeVisitsHeading {
  color: #000;
  font-family: "Sathoshi", sans-serif;
  text-align: center;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
  padding-top: 20px;
}

.homeVisitsPara {
  text-align: left;
  font-family: "Sathoshi", sans-serif;
  padding: 25px;
  padding-left: 50px;
  font-size: 20px;
  margin-top: 0px;
  letter-spacing: 1px;
  padding-top: 0px;
  line-height: 33px;
  width: 90%;
  align-self: center;
  text-align: left;
}

.descriptionHomeVisits {
  color: #000;

  font-family: "Sathoshi", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5; /* 133.333% */
  letter-spacing: 1px;
  text-align: left;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.descriptionHomeVisitsContainer {
  margin-top: 15px;
  padding: 15px;
}

.arrangeInCenter {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-top: 10px;
  gap: 30px;
}

.CarouselHomeVisitsContainer {
  padding-bottom: 50px;
  background: white;
  padding: 24px;
  padding-top: 0px;
  display: none;
}

.photoGalleryHeading {
  text-align: center;
  font-size: 16px;
}

.projectLeaderContainerHomeVisits {
  width: 92%;
  background-color: #f0ece9;
  display: flex;
  margin: auto;
  padding: 30px;
  border-radius: 16px;
  margin-bottom: 30px;
}

.leaderHomeVisitsImage {
  width: 150px;
  height: 150px;
  align-self: center;
  border-radius: 10px;
}

.mainLeaderContainer {
  display: flex;
  background-color: #f1f5f9;
  /* border: 1px black solid; */
  border-radius: 10px;
  padding: 15px;
  margin: 15px;
}

.leaderHomeVisitsTextContents {
  margin-left: 20px;
}
.projectLeaderHomeVisits {
  width: 40%;
  align-self: center;
}

.nameLeaderHeadingHomeVisits {
  color: #2e4049;
  text-align: left;
  font-size: 26px;
  margin-bottom: 10px;
  font-family: "Sathoshi", sans-serif;
}

.horizontalLineHomeVisits {
  border: 1px solid black;
}

.roleLeaderHomeVisits {
  color: #727272;
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
}

.leaderHeadingHomeVisits {
  text-align: left;
  margin-left: 20px;
}

.descriptionLeaderHomeVisits {
  color: #727272;
  font-size: 18px;
  text-align: left;
}

.volunteerHomeVisitsImage {
  width: 110px;
  height: 110px;
  align-self: center;
  margin-left: 10px;
  border-radius: 10px;
}

.volunteerContainerHomeVisits {
  background-color: #f1f5f9;
  border-radius: 20px;
  padding: 20px;
  display: flex;

  width: 40%;
  margin: 10px;
}

.arrangeHomeVisitsVolunteer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 38px;
  padding-bottom: 32px;
}

.volunteerHomeVisitsMainContainer {
  width: 70%;
  padding: 20px;
  padding-right: 0px;
}

.volunteerMainHeadingHomeVisits {
  color: #2e4049;
  font-size: 16px;
  text-align: left;
  margin-bottom: 10px;
}

.volunteerTextContentsHomeVisits {
  margin-left: 10px;
  padding: 10px;
}

.volunteerSubHeadingHomeVisits {
  text-align: left;
  color: #727272;
  margin-bottom: 10px;
}

.volunteerDescriptionHomeVisits {
  text-align: left;
  font-size: 14px;
}

.regularVolunteerHeading {
}

.RegularVolunteerParticipantsHeading {
  width: 85%;
  margin: auto;
}

.leftButtonHomeVisitsNext {
  margin-top: 20px;
}
.rightButtonHomeVisitsNext {
  margin-top: 24px;
}

@media screen and (min-width: 786px) {
  .photoGalleryHeading {
    font-size: 42px;
  }
}
@media screen and (max-width: 768px) {
  .homeVisitsHeading {
    text-align: center;
    margin-top: 20px;
  }
  .rightButtonHomeVisitsNext {
    width: 20px;
  }
  .leftButtonHomeVisitsNext {
    width: 20px;
  }
  .arrangeHomeVistsArrange {
    margin-top: 5px;
  }

  .homeVisitsPara {
    font-size: 16px;
    width: 90%;
    align-self: center;
    line-height: 1.4;
    padding-left: 50px;
    margin-top: 0px;
  }
  .homeVisitsContainerContents {
    margin-top: 40px;
  }
  .arrangeHomeVisitsVolunteer {
    margin-top: 15px;
  }
  .ourActivitiesHeading {
    text-align: center;
    padding-left: 0px;
  }
  .listStyleItemHomeVisits {
    justify-content: center;
    padding-left: 0px;
    width: 100%;
  }
  .descriptionLeaderHomeVisits {
    font-size: 12px;
  }
  .subHeadingHomeVisits {
    font-size: 16px;
  }

  .arrangeInCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
  }

  .descriptionHomeVisitsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 0px;
    width: 100%;
  }

  .descriptionHomeVisits {
    width: 85%;
    font-size: 16px;
  }

  .roleLeaderHomeVisits {
    font-size: 12px;
  }

  .ourActivitiesHeading {
    font-size: 32px;
  }
  .descriptionHomeVisitsContainer {
    margin-top: 10px;
  }
  .homeVisitsHeading {
    font-size: 32px;
  }

  .homeVisitsContainerContents {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .projectLeaderContainerHomeVisits {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .horizontalLineHomeVisits {
    display: none;
  }
  .mainLeaderContainer {
    /* border: 2px solid black; */
    margin: 0px;
    margin-top: 24px;
  }
  .volunteerContainerHomeVisits {
    /* border: 2px solid black; */
  }
  .projectLeaderHomeVisits {
    width: 95%;
  }
  .nameLeaderHeadingHomeVisits {
    font-size: 16px;
  }
  .volunteerHomeVisitsMainContainer {
    padding: 0px;
    width: 100%;
  }
  .leaderHeadingHomeVisits {
    text-align: center;
    font-size: 23px;
    margin-top: 24px;
    margin-left: 0px;
  }
  .regularVolunteerHeading {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 0px;
    text-align: center;
  }
  .volunteerContainerHomeVisits {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
  }
  .volunteerMainHeadingHomeVisits {
    text-align: left;
  }
  .volunteerSubHeadingHomeVisits {
    text-align: left;
  }
  .volunteerTextContentsHomeVisits {
    padding: 20px;
  }
  .leaderHomeVisitsImage {
    height: 132px;
    width: 110px;
    margin-left: 27px;
  }
  .volunteerHomeVisitsImage {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 650px) {
  .volunteerContainerHomeVisits {
    width: 80%;
  }
  .volunteerHomeVisitsImage {
    height: 200px;
    width: 200px;
  }
}

@media screen and (max-width: 1024px) {
  .CarouselHomeVisitsContainer {
    display: block;
  }
}
