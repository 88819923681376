.TestimonialsCard-app-container {
  display: flex;
}

.TestimonialsCard-container {
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  gap: 40px;
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .TestimonialsCard-container {
    /* Align items to the start */
    gap: 15px; /* Reduce the gap between items */
  }
}

.TestimonialsCard-image {
  width: 145.673px;
  height: 152.61px;
  flex-shrink: 0;
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .TestimonialsCard-image {
    width: 120px;
    height: 120px;
  }
}

.TestimonialsCard-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  text-align: start;
}

.TestimonialsCard-description {
  display: flex;
  color: #000;
  font-family: "Satoshi, sans-serif";
  font-size: 17.837px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
}

/* @media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .TestimonialsCard-description {
    font-size: 12px;
  }
} */

.TestimonialsCard-name {
  color: #000;
  font-family: "Satoshi, sans-serif";
  font-size: 24.774px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
}

.double-quotes-image {
  height: 53px;
  width: 83px;
  object-fit: contain;
  margin-right: 50px;
  position: relative;
  left: -5%;
}

.horizotal-line2 {
  height: 0.991px;
}
