.education-main-container {
  height: 100%;
}

.header-image-container {
  width: 100%;
}

.header-image {
  width: 100%;
  height: 300px;
}

@media screen and (max-width: 768px) {
  .header-image {
    height: 200px;
  }
}

.education-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 39px;
  margin-top: 50px;
}

@media screen and (max-width: 992px) {
  .education-container {
    margin-top: 25px;
  }
}

.education-buttons-container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.education-cards-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 72px;
  background-color: #ffffff;
  /* padding-top: 50px; */
}

@media screen and (max-width: 768px) {
  .education-cards-main-container {
    padding-top: 0px;
  }
}
