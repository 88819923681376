.environment-image {
  height: 300px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .environment-image {
    height: 200px;
  }
}

.Environment-sub-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  width: 90%;
  margin: auto;
  margin-top: 18px;
  margin-bottom: 38px;
  /* padding: 30px; */
  border-radius: 8px;
}

.environment-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0ece9;
  padding: 30px;
  border-radius: 16px;
}

.environment-heading {
  color: #222;
  text-align: center;
  font-family: "Sathoshi", sans-serif;
  font-size: 32px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 50px; /* 156.25% */
  text-transform: capitalize;
  margin-top: 0px;
}

@media screen and (max-width: 768px) {
  .environment-heading {
    font-size: 28px;
  }
}

.environment-description {
  color: #7a7a7a;
  text-align: center;
  font-family: "Sathoshi", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px;
}

@media screen and (max-width: 768px) {
  .environment-description {
    font-size: 16px;
  }
}

.environment-cards-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  align-self: stretch;
  padding-left: 34px;
}

.environment-card-heading {
  color: #222;
  text-align: center;
  font-family: "Sathoshi", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 156.25% */
  text-transform: capitalize;
  margin-bottom: 0px;
  margin-top: 0px;
}

@media screen and (max-width: 992px) {
  .environment-card-heading {
    font-size: 28px;
  }
}

.environment-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 50px;
}

@media screen and (max-width: 992px) {
  .environment-cards {
    flex-direction: column;
    width: 100%;
  }
}

.environment-cards-text-container {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 20px; /* Add margin-bottom to create space between this container and the next */
  padding-left: 8px;
}

@media screen and (max-width: 992px) {
  .environment-cards-text-container {
    gap: 16px;
  }
}

.environment-cards-text {
  color: #222;
  font-family: "Sathoshi", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 208.333% */
  text-transform: capitalize;
  text-align: left;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .environment-cards-text {
    font-size: 18px;
    line-height: 24px;
  }
}

.environment-cards-image-container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  border: 1px solid #000;
}

@media screen and (max-width: 992px) {
  .environment-cards-image-container {
    justify-content: center;
  }
}

.environment-cards-image {
  width: 100%;
}

.clicked {
  color: #36b0b8;
  font-family: "Sathoshi", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  border: 1px solid #000;
  padding: 10px;
  border-radius: 8px;
}
