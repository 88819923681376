* {
  margin: 0;
}

body {
  margin: 0;
  font-family: "Satoshi", sans-serif;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  font-family: "Roboto";
}

.copyright {
  margin: 2px;
  text-align: center;
  font-family: "Calibri", sans-serif;
}

@media screen and (max-width: 768px) {
  .copyright {
    font-size: 12px;
  }
}
